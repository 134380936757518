@import 'factor-lib/scss/base/colors';
@import 'factor-lib/scss/override/mixins';

.p-splitpanes {

    // override default
    height: inherit !important;
    position: static !important;

    .Pane {
        @include custom-scrollbar(7px, 7px, $grey, $white);
        overflow-y: scroll;
    }

    .Resizer {
        cursor: col-resize;
    
        background-color: $white;
        width: 12px;
        position: relative;
        box-shadow: 0 0 2px 0 $black inset;
    
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            border-radius: 4px;
            background-color: $grey; // #bbc6cd;
            transform: translateX(-50%);
            width: 4px;
            height: 75px;
        }
    }
}
@import '../../scss/components/form';
@import '../../scss/base/layouts';
@import '../../scss/base/borders';
@import '../../scss/times';

@import 'select';

@mixin f-multiple-select {
    //@extend .f-select;

    &__control {
        // On a des tailles par défaut différentes dans le customer (les invoices status) et la partie admin
        //@extend .p-size-7;
    }

    &__multi-value {
        //@extend .p-size-7;
        background-color: transparent !important; // By default, grey

        align-items: center;
    }
}


.f-multiple-select-enabled {
    @include f-multiple-select;
    @include f-select-enabled;

    &__multi-value {

        &__remove {
            cursor: pointer;

            background-color: $grey;

            // Round shape
            width: $size-62;
            height: $size-62;
            border-radius: 290486px !important; // $p-radius-rounded !important;

            transition: $long-time;

            &:hover {
                //background-color: $danger-color !important;
            }
        }
    }

    &__option {
        .p-cb-border-checked {
            border: $border-default-style $validation-ok-color !important;
        }
    }
}

.f-multiple-select-disabled {
    @include f-multiple-select;
    @include f-select-disabled;
}

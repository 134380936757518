@import '../../scss/base/colors';
@import '../../scss/base/borders';
//@import '../../scss/base/background';
//@import '../../scss/components/form';

.p-rb-border {

    // @extend .p-icon;

    // TODO : why not ?
    // display: flex;
    // justify-content: center;

    // width: 20px;
    // height: 20px;

    border: $border-default-style #95a6b0; // Color duplicated, but does not seem related

    position: relative;
}

.p-rb-checkmark {
    background-color: #4A4A4A; // Specific colors
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 6px;
    height: 6px;
    border-radius: 8px;
}

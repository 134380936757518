@import '../components/input';

.p-overlappingProgressBarWrapper {
  position: relative;
}

.p-overlappingProgressBar {
  background-color: $green;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}
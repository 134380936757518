@import '../../scss/base/layouts';
@import '../../scss/base/typography';
@import '../../scss/base/colors';
@import '../../scss/base/background';
@import '../../scss/components/form';
@import '../../scss/components/input';

@mixin f-select {

    &__control {

        min-height: $control-default-height !important;
        border-radius: $input-border-radius !important;
        // box-shadow: 0 0 0 1px ;

    }

    //&__placeholder {
    //    font-family: $main-family !important;
    //}

    &__indicator-separator {
        display: none !important;
    }

}

@mixin f-select-enabled {

    @include f-select;

    &__control {

        // Default overrides
        &--is-focused {
            border-color: $input-active-border-color !important;
            box-shadow: 0 0 0 1px $input-active-border-color !important; // $black; //  !important;
        }

        &--menu-is-open {
            // border-color: $input-active-border-color !important;
            box-shadow: 0 0 0 1px $input-active-border-color !important; // $black; //  !important;
        }
    }

    //&__placeholder {
    //    font-family: $main-family !important;
    //}

    // La flèche vers le haut / bas
    &__indicator {
        color: $green !important; //  $primary !important;
        cursor: pointer !important;
    }

    &__menu {
        margin-top: 1px !important;
    }

    &__menu-portal {
        display: none !important;
    }

    &__option {
        color: $text-color; // #484E4D; // $grey-3!important;
        background-color: $white !important;

        @extend .p-control-size;

        // hover
        &--is-focused {
            //@extend .p-active-input;
            background-color: $background-color !important; // as previous, but with important
            //background-color: $light-green !important;
        }

        &--is-selected {
            // when selected, react-select overrides the color with white
            // we will override their override without our default text color
            // TODO: we lose our custom coloring, find another fix, not important for now as this only impacts multiselect
            color: $text-color !important;
        }
    }
}

@mixin f-select-disabled {
    @include f-select;
}

@import '../base/borders';

// TODO : not sure why this is needed

.p-table {
  // The table has rounded corner and the default border style
  @extend .p-border-small;

  // By default, border-collapse make the inner cell border properties take
  // precedence over the outer table properties. Hence the border-radius is not
  // applied properly. Disabelling border-collapse allow to use border-radius
  // on the table element.
  border-collapse: separate;

  // TODO : rename to p-min-width-column ?
  .minSize {
    width: 1%;
  }

  // I wanted to put it in th .minSize but it is not working
  .p-no-wrap {
    white-space: nowrap !important;
  }

  th {

    // https://stackoverflow.com/questions/3440815/vertically-align-a-div-within-a-table-th
    vertical-align: middle !important;
  }
}

.p-il-table {
  td {
    vertical-align: middle !important;
  }
}

@import 'scss/base/colors';

$modal-close-icon-resting-color: $green;
$modal-close-icon-resting-hover: $danger-color;
$modal-close-icon-resting-focus: lighten($modal-close-icon-resting-hover, 5%);
$modal-close-icon-resting-active: darken($modal-close-icon-resting-hover, 5%);

$modal-overlay-color: rgba($grey-2, .6);


// @import 'PEtienneModal/p-etienne-modal';

// @import './p-etienne-modal';

//@import '../../scss/base/layouts';
//@import '../../scss/base/borders';

.p-closebutton {

    stroke: $modal-close-icon-resting-color;

    &:hover {
        stroke: $modal-close-icon-resting-hover;
    }

    &:focus {
        stroke: $modal-close-icon-resting-focus;
    }

    &:active {
        stroke: $modal-close-icon-resting-active;
    }
}

@import 'colors';

//$border-color-alt: $grey-1;
$border-default-style: 1px solid;
//$border-dashed-style: 1px dashed;

$border-radius: 5px; // used by inputs

$border-default: $border-default-style $border-default-color;

//$border-secondary: $border-style $secondary; // Only used in a specific component
//$border-dashed: $border-dashed-style $border-color;
//$border-dashed-alt: $border-dashed-style $border-color-alt;

//$border-color-white-1: $white-6;
//$border-color-white-22: #e9ecee; // TODO : use somewhere else ?

//$border-radius-large: 10px;

// TODO : rename ?
//$p-radius-rounded: 290486px;

.p-default-border {
    border: $border-default-style;
}

.p-border-default-color {
    border-color: $border-default-color; // $divider-color;
}

// TODO : should be used more
// TODO : rename
.p-border {
    @extend .p-border-default-color;
    @extend .p-default-border;
}

$border-radius-small: 3px;

.p-b-small {
    border-radius: $border-radius-small;
}

.p-border-small {
    @extend .p-border;
    @extend .p-b-small;
    // border-radius: $border-radius;
}

.p-ok-border {
    @extend .p-default-border;
    border-color: rgba($validation-ok-color, 0.3) !important;
}

.p-warn-border {
    @extend .p-default-border;
    border-color: $warning-color;
}

.p-error-border {
    @extend .p-default-border;
    border-color: rgba($danger-color, 0.3) !important;
}

.p-border-down {
    border-bottom: $border-default-style $border-default-color;
}
@import 'factor-lib/scss/base/colors';

$topbar-background-color: $bg-light-green;

.f-navbar {
    background-color: $topbar-background-color !important;
}

.f-navbar-selected {
    background-color: lighten($topbar-background-color, 5%) !important;
}

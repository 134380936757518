
.p-spinner {
    animation: spinAround 500ms infinite linear;
    border: 2px solid; //  #dbdbdb;
    border-radius: 290486px;
    border-right-color: transparent;
    border-top-color: transparent;
    display: block;
    height: 1em;
    position: relative;
    width: 1em;
}

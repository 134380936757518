@import './form';

$input-hover-border-color: $input-border-color; // Pour desactiver partout par default. (par exemple sur les textarea)

.p-base-input {
  // Conflicts on height...
  @extend .p-control;

  // TODO : a lof ot duplication with InvoiceDatePicker


  // Reset inset box-shadow
  box-shadow: none;
  //padding: 0 !important;

  // Focused state
  // the component display a colored border and shadow
  &:active,
  &.is-focused {
    border-color: $input-active-border-color;
    //box-shadow: $input-active-box-shadow;
  }

  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
  }
}

.p-input {

  @extend .p-base-input;

  &.is-valid .error-message {
    // I don't know
    // color: transparent;
  }

  // Error state
  &:not(.is-valid) {
    border-color: $danger-color;
    //box-shadow: $drop-shadow-size-default $danger-fade-1;

    .error-message {
      color: $danger-color;
    }
  }
}

.p-i-revert {
  display: flex !important; // To override input in amount input
  flex-direction: row-reverse !important; // To override input in amount input
  align-items: stretch !important; // To override input in amount input
  // flex-grow:100% ; // TODO !!
}

.p-i-rounded {
  border-radius: calc(#{$control-default-height} /* TODO : include margins ? */ / 2) !important;
}

.p-round-right {
  border-top-right-radius: $input-border-radius;
  border-bottom-right-radius: $input-border-radius;
}

.p-search-box {

  // @extend .input;
  @extend .p-control;
  @extend .p-base-input;
  @extend .p-i-rounded;

  // Taken from bulma.
  padding: calc(.5em - 1px) calc(.75em - 1px);

  &.active,
  &.is-focused {
    border-color: $input-active-border-color;
    box-shadow: $black;
  }
}

.p-active-input {
  // A little 'coincidence' that it is same color as background
  background-color: $background-color;
}
@import '../../scss/base/colors';
@import '../../scss/base/typography';


$label-color: #95a6b0; // Color duplicated, but does not seem related

.p-i-m {

    // display label and input as a column, aligned left
    display: flex;
    flex-direction: column;
    // align-items: flex-start;
    justify-content: center;

    input {
        // Reset default rules
        border: none;
        padding: 0;
        // max-width: 100%;

        // @extend .p-size-default;

        caret-color: $validation-ok-color;
        // line-height: $p-hint-line-height;

        outline: none;
    }

    input:disabled {
        background: inherit; // Override default which I don't know where it comes from
    }

    input::-webkit-input-placeholder {
        color: $label-color; // Color duplicated, but does not seem related
    }

    .p-label {
        font-weight: inherit;
        line-height: 1.5;
        @extend .p-size-10;

        .p-field-name {
            color: $label-color; // Color duplicated, but does not seem related
        }
    }

    .error-message {
        .is-valid & {
            color: transparent;
        }

        :not(.is-valid) & {
            color: $danger-color;
        }
    }
}


.p-right-background-color {
    background-color: #bbc6cd; // TODO : shared ?
    color: $white;
}

@import "../base/layouts";
@import "../base/icons";
@import '../base/typography';

$stepper-color: $grey; // #dee3e6; // Does this exist somewhere ?

.p-stepper-round {
  @extend .p-icon;
  z-index: 2; // Over the line
  font-size: $size-9;
}

.p-not-done {
  background-color: $stepper-color;
  // Color: leave default ?
}
@import 'factor-lib/scss/base/typography';

// For admin, default is 16px;
$body-font-size: $size-62;

@import './topbar';

// @import "bulma/sass/utilities/_all.sass";

$navbar-tab-active-background-color: lighten($topbar-background-color, 5);
$navbar-item-hover-background-color: lighten($topbar-background-color, 5);
$title-size: 1.75rem;
$title-color: #666;
// Used notably for tabs. In customers as well ?
$size-normal: $size-62;

// Apres ceux précédents, pour qu'on puisse overrider
@import "bulma/sass/base/generic.sass";
@import "bulma/sass/elements/box.sass";
@import "bulma/sass/elements/container.sass";

@import "bulma/sass/components/navbar.sass";
@import "bulma/sass/components/tabs.sass";
@import "bulma/sass/components/panel.sass";

// @import "bulma/sass/form/select.sass";

//@import "bulma/sass/grid/_all.sass";
@import "bulma/sass/grid/columns.sass";

// Apres bulma, pour que le body font-size 90% s'applique apres la taille de bulma
@import 'factor-lib/index.scss';
@mixin arrow($color: transparent) {
  $arrow-size: 3.5px;

  border-radius: 0;
  height: 0;
  width: 0;
  border: $arrow-size solid $color;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  margin-top: calc(-#{$arrow-size} / 2);
  top: calc(50% - #{$arrow-size});
  transform: rotate(-45deg);
  transform-origin: center;
}

%arrow {
  @include arrow()
}

@mixin custom-scrollbar($w, $h, $color-thumb, $color-track) {
  // firefox scrollbar styling
  // @todo handle size based on $w & $h
  scrollbar-width: thin;
  scrollbar-color: $color-thumb $color-track;

  // Chrome scrollbar styling
  &::-webkit-scrollbar {
      width: $w;
      height: $h;
  }

  &::-webkit-scrollbar-track-piece  {
      background-color: $color-track;
  }

  &::-webkit-scrollbar-thumb:vertical {
      height: 30px;
      background-color: $color-thumb;
      border-radius: 4px;
  }
}

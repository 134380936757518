@import '../base/colors';

.p-background {
  background-color: $background-color;
}

// This is the default, thus we should not use it
.p-bg-white {
  background-color: $white !important;
}

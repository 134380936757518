
@import './base/colors';
@import './base/background';
@import './base/typography';
@import './base/borders';
@import './base/layouts';
@import './base/clickable';

/*
@import 'base/layouts';
@import 'animations';
@import 'icons';
@import 'components/button';
@import 'components/form';
@import 'components/input';

// Use Bulma mixins like until
@import "~bulma/sass/utilities/mixins.sass";
*/

/*
body {
  font-family: $main-family; // 'Open sans', sans-serif;
}
*/

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

// Shared with Modals and Bulma

$modal-overlay-color: rgba($grey-2, .6);

.svg-fill-green {
  fill: $green;
}

.svg-fill-primary {
  fill: $green;
}

// Messages

// TODO : rename to banneer
.p-message {
  @extend .p-padding-5;
  border-radius: $border-radius;
}

// TODO : rename to banner ?
.p-error-message {
  // TODO : no (text / stoke) color ?
  @extend .p-message;
  @extend .p-error-border;
  background-color: rgba($error-color, 0.08);
}

// TODO : rename to banner ?
.p-ok-message {
  @extend .p-message;
  @extend .p-ok-border;
  background-color: rgba($validation-ok-color, 0.08);
}

.p-horizontal-divider {
  @extend .p-border-down;
  height: 1px;
}

.p-ok {
  background-color: $validation-ok-color;
  color: $white;
  fill: $white;
}

.p-danger {
  background-color: $danger-color;
  color: $white;
  fill: $white;
}

.p-warn {
  background-color: $warning-color;
  color: $white;
  fill: $white;
}

.p-warn-header {
  background-color: rgba(247, 162, 83, 0.08);
}

.p-warn-icon {
  stroke: $warning-color;
  color: $warning-color;
}

.p-striped {
  @extend .p-background;
}
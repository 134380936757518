@import 'scss/base/layouts';
@import 'scss/base/borders';

.modal-card-body {
    // padding: $space-4;
    border-radius: $border-radius;
}

.full-height .modal-card {
    height: calc(100% - 2 * #{$space-5});
}

.p-close {
    position: absolute;
    top: $space-5;
    right: $space-5;
}

// This file should contains all the variables and mixins.
// /!\ This file is loaded with every component.
// @import "./utilities/power";

// Custom styles
@import "./style";

@import "./base/icons";
@import "./base/clickable";
@import "./base/background";
@import "./base/shadow";
@import "./components/stepper";
@import './components/table';
@import './components/upload';
@import './components/form';
@import './components/input';

// Ci dessous, les modules de bulma qu'on utilise partout
@import "./variables-override";

@import "bulma/sass/base/generic.sass"; // Remove many default browser layouts (ex: underline "a" (links))
@import "bulma/sass/base/minireset.sass"; // Remove many default browser layouts (ex : body margins)

@import "bulma/sass/utilities/_all"; // Notably needed by the following one
@import "bulma/sass/components/level.sass";
@import "bulma/sass/components/modal.sass";
@import "bulma/sass/elements/table.sass";
@import "bulma/sass/elements/title.sass";

@import "bulma/sass/form/shared.sass";
@import "bulma/sass/form/input-textarea.sass";
@import "bulma/sass/form/tools.sass"; // Les 'field'

@import "bulma/sass/base/animations.sass"; // Necessaire pour le spinner


html {
  // Override bulma scroll default
  overflow-y: auto;
  //font-size: 100%;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 90%;
  }
}

// Duplicated, but necessary because of order
// Override bulma default size
.control {
  // @extend .p-control-size; // Ideally, but does not work
  font-size: $size-7;
}

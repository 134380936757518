@import "../utilities/power";

// @import "borders";

$space-base: 10px; // @todo change to 0.625rem ? or use em ?
$space-ratio: 1.618; // Golden ratio

$space-1: $space-base * pow($space-ratio, 5);
$space-2: $space-base * pow($space-ratio, 4);
$space-3: $space-base * pow($space-ratio, 3);
$space-4: $space-base * pow($space-ratio, 2);
$space-5: $space-base * pow($space-ratio, 1);
$space-6: $space-base * pow($space-ratio, 0);
$space-7: calc(#{$space-base} * #{pow($space-ratio, -1)});
$space-8: calc(#{$space-base} * #{pow($space-ratio, -2)});
$space-9: calc(#{$space-base} * #{pow($space-ratio, -3)});

$spaces: $space-1, $space-2, $space-3, $space-4, $space-5, $space-6, $space-7, $space-8, $space-9;

@media (max-width: 940px) {
  .p-content {
    width: 100%;
    margin: 0 auto;
  }
}

@media (min-width: 940px) {
  .p-content {
    width: 940px;
    margin: 0 auto;
  }
}

// TODO : remove ?
.p-horizontal-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  // TODO : what ?
  flex-wrap: wrap;
}

// TODO : remove, use flex
.p-full-width {
  width: 100%;
}

// TODO : remove, use flex
.p-full-height {
  height: 100%;
}

.p-no-flex {
  flex: 0 0 auto;
}

// https://stackoverflow.com/questions/28700805/aligning-elements-left-and-center-with-flexbox/44348868#44348868
// Rename to flex 1 ?
.p-empty-flex-align {
  flex: 1;
}

.p-flex-1 {
  flex: 1;
}

// TODO : merge ?
.p-half {
  flex: 1 1 0;
}


.p-shrink-1 {
  flex-shrink: 1 !important;
}

.p-default-flex-item {
  flex: 1 1 auto;
  // https://stackoverflow.com/questions/38937376/flexbox-parent-smaller-than-child-elements-on-browser-resize
  // min-width: 0;
}

/*
.p-full-screen-height {
  height: 100vh;
}
 */

.p-full-container {
  overflow: auto;
  @extend .p-full-width;
  @extend .p-full-height;
}

// https://stackoverflow.com/questions/8555820/set-div-to-remaining-height-using-css-with-unknown-height-divs-above-and-below
// TODO : remove, and use table / tr directly ?
.p-full-height-stack {
  display: table;
  // height must be determined elsewhere
  // height: 100%;
  // width: 100%;
}

.p-full-stack-header {
  display: table-row;
}

.p-with-topbar {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.p-vertical-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  // Ideally, but applies to all children
  // text-align: center;
}

/*
.p-row-top {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
} */

.p-horizontal-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.p-horizontal-stretch {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.p-vertical-stretch {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

// TODO : probably remove
.p-full-height-stack-remaining {
  display: table-row;  /* height is dynamic, and will expand... */
  height: 100%;        /* ...as content is added (won't scroll) */
}

.p-both-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.p-right {
  display: flex;
  flex-direction: row-reverse;
}

$sides: 'top' 'right' 'bottom' 'left';

//@each $side in $sides {
//  .p-#{$side}-bordered {
//    border-#{$side}: $border-default;
//  }
//}
//
//@each $side in $sides {
//  .p-#{$side}-highlighted {
//    border-#{$side}: $border-default;
//    padding-#{$side}: $space-5;
//  }
//}

// @import "~bulma/sass/utilities/initial-variables.sass";
// @import "~bulma/sass/utilities/mixins.sass";

/*
$devices: (
  "tablet": $tablet,
  "desktop": $desktop,
  "widescreen": $widescreen,
  "fullhd": $fullhd
);
 */

$spacers: ("margin", "padding");

@each $spacer in $spacers {

  @each $space in $spaces {
    $i: index($spaces, $space);

    // TODO : maybe use ?
    //// p-margin-top-4-desktop, p-padding-top-4-desktop
    //@each $device-name, $device-size in $devices {
    //  @include from($device-size) {
    //    @each $side in $sides {
    //      .p-#{$spacer}-#{$side}-#{$i}-#{$device-name} {
    //        #{$spacer}-#{$side}: $space !important;
    //      }
    //    }
    //  }
    //}

    // p-margin-top-4, p-padding-top-4
    @each $side in $sides {
      .p-#{$spacer}-#{$side}-#{$i} {
        #{$spacer}-#{$side}: $space !important;
      }
    }

    //// p-margin-4-desktop, p-padding-4-desktop ...
    //@each $device-name, $device-size in $devices {
    //  @include from($device-size) {
    //    .p-#{$spacer}-#{$i}-#{$device-name} {
    //      #{$spacer}: $space !important;
    //    }
    //  }
    //}

    // p-margin-4, p-padding-4 ...
    .p-#{$spacer}-#{$i} {
      #{$spacer}: $space !important;
    }
  }

  //@each $device-name, $device-size in $devices {
  //  @include from($device-size) {
  //
  //    // p-marginless-top-desktop, p-paddingless-top-desktop ...
  //    @each $side in $sides {
  //      .p-#{$spacer}less-#{$side}-#{$device-name} {
  //        #{$spacer}-#{$side}: 0;
  //      }
  //    }
  //
  //    // p-marginless-desktop, p-paddingless-desktop
  //    .p-#{$spacer}less-#{$device-name} {
  //      #{$spacer}: 0;
  //    }
  //  }
  //}

  // p-marginless, p-paddingless
  .p-#{$spacer}less {
    #{$spacer}: 0;
  }
}


.p-page-container > :not(:last-child) {
  @extend .p-margin-bottom-4;
}

.p-user-container {
  max-width: 66%;
}

@media screen and (max-width: 1440px) {
  .p-user-container {
    max-width: 90%;
  }
}

.p-before-hero {
    visibility: hidden;
    //margin-top: -50%;
    //scale: 1 0.5;
}

.p-after-hero {
    z-index: 20;
    transform: translateY(-50%);
}

.p-td-valign-middle {
    vertical-align: middle
}

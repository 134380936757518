
//@import "~font-awesome/scss/font-awesome";
// TODO : how can we bundle ?
// Last comment of https://github.com/jgthms/bulma/issues/2509
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,600,700,800&display=swap');

@import 'colors';

$main-family: 'Poppins', sans-serif;

// http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
//$size-1: 2rem; // 32px
$size-2: 1.875rem; // 30px
$size-3: 1.75rem; // 28px
//$size-35: 1.5rem; // 24px
$size-4: 1.375rem; // 22px
$size-5: 1.25rem; // 20px
$size-6: 1.125rem; // 18px
$size-65: 1.0625rem; // 17px
$size-62: 1rem; // 16 px
//$size-default: 1rem; // 16px
//$base-size: 0.875rem; // Notre ancien 7, qui est la valeur par défaut dans le front customer
$size-7: 0.875rem; // 14 px // Is now the default
$size-75: 0.75rem; // 13.712 px
$size-8: 0.64rem; // not standard, somewhere between 10px & 11px
$size-9: 0.625rem; // 10 px
$size-10: 0.633rem; // 10.125px

//.p-size-1 {
//  font-size: $size-1;
//}

.p-size-2 {
  font-size: $size-2;
}

.p-size-3 {
  font-size: $size-3;
}

.p-size-4 {
  font-size: $size-4;
}

.p-size-5 {
    font-size: $size-5;
}

.p-size-6 {
  font-size: $size-6;
}

.p-size-62 {
  font-size: $size-62;
}

.p-size-7 {
  font-size: $size-7;
}

//.p-size-default {
//  font-size: $size-default;
//}

//.p-size-7 {
//  font-size: $size-7;
//}

.p-size-75 {
    font-size: $size-75;
}

.p-size-9 {
  font-size: $size-9;
}

.p-size-10 {
  font-size: $size-10;
}

//$text-color: #687782; // TODO : something else ?
// $text-light: $grey-2;
//$text-strong:   #2c3e4f;
//$text-disable-color: $white-6;
//$text-select-color: $grey-1;
//$text-dim-color: $grey-1;
//$text-light-color: $white-1;
//$table-text-color: $text-dim-color;
//$invert-full-white: $white-0;
//
//// $weight-semibold: 600;
//$weight-bold: 700;

$slight-opacity: 0.8;

$p-title-text-color: $text-color;

// Headings

// Maintained for compatibility
.p-title-text-color {
  color: $p-title-text-color !important;
}

.p-bold {
  font-weight: 600; // $weight-semibold;
}

.p-complement-message {
  color: $grey; // $grey-2;
}

.p-ok-text {
    // @extend .p-bold;
    color: $validation-ok-color;
}

.p-warn-text {
  color: $warning-color !important;
}

.p-danger-text {
  // @extend .p-bold;
  color: $danger-color !important;
}

// TODO : rename to text
// An alias for now
.p-error-message {
  @extend .p-danger-text;
}

.p-very-light {
  color: $text-color;
  opacity: 0.4;
}

.p-no-wrap {
  white-space: nowrap;
}

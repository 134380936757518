
@import 'colors';
@import 'layouts';
@import 'borders';
@import 'typography';

.p-icon-extended {
  // Content is always centered
  @extend .p-both-center;

  text-align: center;
  border-radius: 50%;
}

// TODO : looks duplicated with icons ?
$round-size: 20px;

// Size
// Rename to default for the sizes ?
.p-icon {
  @extend .p-icon-extended;
  height: $round-size;
  width: $round-size;
}

.p-error-icon {
  @extend .p-icon;
  @extend .p-error-message;

  // TODO : what about background ?
  // TODO : extends
  @extend .p-error-border;
  // border: 1px solid $error-color;
}

@import '../scss/base/colors';
@import '../scss/base/borders';
@import '../scss/components/form';

// https://www.w3schools.com/howto/howto_css_custom_checkbox.asp

// TODO : p-horizontal-center ?
.p-cb-wrapper {
    display: flex;
    align-items: center;
}

.p-cb-border {
    width: 20px;
    height: 20px;
    // border-radius: $border-radius-small;

    position: relative;
    border-radius: $input-border-radius; // !important;
}

.p-cb-border-unchecked {
    //@extend .p-border-control;
    border: $border-default-style #dee3e6; // Comme shadow, mais pas lié
}

.p-cb-border-checked {
    border: $border-default-style $validation-ok-color;
}

.p-cb-checkmark {
    color: $validation-ok-color;
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

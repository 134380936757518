@import '../../scss/base/colors';
@import '../../scss/base/shadow';
@import '../../scss/base/layouts';
@import '../../scss/components/form';
@import '../../scss/components/input';
@import '../../scss/times';

$menu-border-radius: $border-radius;
$menu-item-height: $control-compact-height;

.f-autocomplete-input {
    @extend .p-full-width;

    position: relative;
    display: inline-block !important;

    .f-autocomplete-toggle {
        @extend .p-both-center;

        width: 2 * $space-5;
        color: $green;
        background-color: transparent;
        border-width: 0;
        outline: none;

        transition: all .2s ease;

        &.on {
            transform: rotate(180deg);
        }
    }

    .f-autocomplete-menu {
        @extend .p-full-width;

        position: absolute;
        z-index: 1;

        border-radius: $menu-border-radius;
        //border: 1px solid $divider-color;
        @extend .p-border;

        background-color: $white;
        box-shadow: $drop-shadow-huge;

        max-height: 5 * $menu-item-height;
        overflow-y: auto;

        .f-autocomplete-item {
            @extend .p-horizontal-center;
            @extend .p-padding-6;
        
            cursor: default;
            height: $menu-item-height;
        
            &:first-child {
                border-radius: $menu-border-radius $menu-border-radius 0 0;
            }
        
            &:last-child {
                border-radius: 0 0 $menu-border-radius $menu-border-radius;
            }
        
            &.highlighted {
                @extend .p-active-input;
            }
        }

        .f-autocomplete-no-items {
            @extend .f-autocomplete-item;
        
            opacity: $slight-opacity;
        }
    }
}

 @import 'scss/base/colors';

 $loader-color: $green;

.p-loader-wrapper {

    margin: 0 auto;
    padding: 8px 0;
    //font-size: .875rem;

    .p-loader {
        box-sizing: border-box; // The way this is currently computed
        width: 48px;
        height: 48px;
        margin: 0 auto;
        border: solid 8px rgba($loader-color, 0.5);
        border-top-color: $loader-color;
        border-radius: 50%;
        animation: spin 2s linear infinite;
        // z-index: 1500; no reason, can overlap on a popup
        position: relative;
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@import '../scss/base/colors';
@import '../scss/base/borders';
@import '../scss/base/typography';
@import '../scss/components/form';
@import '../scss/times';

// Override variable for next call
$pbutton-primary-background: $green;
$pbutton-secondary-background: #176274; // Shared ?

// @import 'kether-shared-lib/button.scss';

//// https://sass-lang.com/documentation/variables
//$pbutton-primary-background: #112233 !default;
//$pbutton-secondary-background: #112233 !default;

// Buttons
// -----------------

// For fixed size
$button-default-width: $control-default-width;
$button-default-height: $control-default-height;

// $button-compact-height: $control-compact-height;
$button-compact-width: $control-compact-width;

$button-weight: bold; // TODO should be $weight-bold

.p-button {

  @extend .p-size-62;
  // Copy-paste from p-both-center
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center; // In case of multiple lines

  // @extend .p-size-default;

  // Default style of the button

  border-radius: calc(#{$button-default-height} /* TODO : include margins ? */ / 2); // TODO : remplacer par l'infini ?
  font-weight: 500; // $button-weight;
  transition: $long-time;
  // TODO : delete, should be the default
  // font-size: $p-text-new-font-size-default; // Override Bulma ?


  // Remove firefox dotted outline, the outline is taken care of by Bulma
  &::-moz-focus-inner {
    border: 0;
  }

  // TODO : faire ça en amont ?
  &.is-fixed {
    width: $button-default-width;
    height: $button-default-height;
  }

  &.is-fluid {
    // Leave width
    height: $button-default-height;
  }

  &.is-compact {
    @extend .p-control-compact-height;
    width: $button-compact-width;
  }

  &.is-disabled {
    opacity: $fade-2;
    cursor: auto; // Remove default
  }

  // Button kinds and states
  &.is-primary {

    $button-style-primary-resting-text-color: $white;
    $button-style-primary-resting-background: $pbutton-primary-background;

    color: $button-style-primary-resting-text-color;
    background: $button-style-primary-resting-background;

    // https://mdigi.tools/lighten-color/#60c589
    $button-style-primary-hover-background: #bfe8d0;

    .p-clickable {
      &:hover,
      &.is-hovered {
        //color: $button-style-primary-resting-text-color;
        background: $button-style-primary-hover-background;
      }
    }

    &:focus,
    &.is-focused {
      //color: $button-style-primary-resting-text-color;
      background: lighten($button-style-primary-hover-background, 5%);
    }

    &:active,
    &.is-active {
      //color: $button-style-primary-resting-text-color;
      background: darken($button-style-primary-hover-background, 5%);
    }

    &.is-disabled {
      //color: $button-style-primary-resting-text-color;
      background: $button-style-primary-resting-background;
    }
  }

  $secondary-alt: $green; // TODO : check

  &.is-secondary {

    $button-style-secondary-resting-text-color: $white;
    $button-style-secondary-resting-background: $pbutton-secondary-background; // $secondary;

    color: $button-style-secondary-resting-text-color;
    background: $button-style-secondary-resting-background;

    &:hover,
    &.is-hovered {
      .p-clickable {
        //color: $button-style-secondary-resting-text-color;
        background: $secondary-alt;
      }
    }

    &:focus,
    &.is-focused {
      //color: $button-style-secondary-resting-text-color;
      background: lighten($button-style-secondary-resting-background, 5%);
    }

    &:active,
    &.is-active {
      //color: $button-style-secondary-resting-text-color;
      background: darken($button-style-secondary-resting-background, 5%);
    }

    &.is-disabled {
      //color: $button-style-secondary-resting-text-color;
      background: $button-style-secondary-resting-background;
    }
  }

  &.is-danger {

    $button-style-danger-resting-text-color: $white;
    $button-style-danger-resting-background: $error-color;

    color: $button-style-danger-resting-text-color;
    background: $button-style-danger-resting-background;

    &:hover,
    &.is-hovered {
      .p-clickable {
        //color: $button-style-danger-resting-text-color;
        background: $secondary-alt;
      }
    }

    &:focus,
    &.is-focused {
      //color: $button-style-danger-resting-text-color;
      background: lighten($button-style-danger-resting-background, 5%);
    }

    &:active,
    &.is-active {
      //color: $button-style-danger-resting-text-color;
      background: darken($button-style-danger-resting-background, 5%);
    }

    &.is-disabled {
      //color: $button-style-danger-resting-text-color;
      background: $button-style-danger-resting-background;
    }
  }

  &.is-outlined {

    $button-style-outlined-resting-border-color: $pbutton-primary-background;
    $button-style-outlined-resting-text-color: $pbutton-primary-background;

    border: $border-default;
    border-color: $button-style-outlined-resting-border-color;
    color: $button-style-outlined-resting-text-color;
    // background: $button-style-outlined-resting-background;

    $button-style-outlined-hover-text-color: $white;
    $button-style-outlined-hover-border-color: $pbutton-primary-background;
    $button-style-outlined-hover-background: $pbutton-primary-background;

    &:hover,
    &.is-hovered {
      .p-clickable {
        color: $button-style-outlined-hover-text-color;
        border-color: $button-style-outlined-hover-border-color;
        background: $button-style-outlined-hover-background;
      }
    }

    &:focus,
    &.is-focused {
      color: $button-style-outlined-hover-text-color;
      border-color: $button-style-outlined-hover-background;
      background: lighten($button-style-outlined-hover-background, 5%);
    }

    &:active,
    &.is-active {
      color: $button-style-outlined-hover-text-color;
      border-color: $button-style-outlined-hover-background;
      background: darken($button-style-outlined-hover-background, 5%);
    }

    &.is-disabled {
      color: $button-style-outlined-resting-text-color;
      border-color: $button-style-outlined-resting-border-color;
      // background: $button-style-outlined-resting-background;
    }
  }
}

/*
.is-loading {
    color: transparent !important;
    pointer-events: none;
}
 */

@import 'select';

@mixin f-single-select {
    //@extend .f-select;

    &__control {
        // @extend .p-size-62;
    }
}

.f-single-select-enabled {
    @include f-single-select;
    @include f-select-enabled;

    &__option {
        &--is-selected {
            background-color: $validation-ok-color !important;
        }
    }
}

.f-single-select-disabled {
    @include f-single-select;
    @include f-select-disabled;
}
